import React, { useEffect } from 'react';

const Matomo = ({ location }) => {
  const siteId = '34';

  useEffect(() => {
    var _paq = window._paq || [];
    window._paq = _paq;
    const getCookieValue = (name) =>
      document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() ||
      '';
    const cookieContents = getCookieValue('cookie-consent');
    if (cookieContents.includes('statistics')) {
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function () {
        var u = '//piwik.app.jyu.fi/';
        _paq.push(['setTrackerUrl', u + 'piwik.php']);
        _paq.push(['setSiteId', siteId]);
        var d = document,
          g = d.createElement('script'),
          s = d.getElementsByTagName('script')[0];
        g.type = 'text/javascript';
        g.async = true;
        g.defer = true;
        g.src = u + 'piwik.js';
        s.parentNode.insertBefore(g, s);
      })();
    }
  }, [location]);

  return <></>;
};

export default Matomo;

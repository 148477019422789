// SemanticUI-free pre-@plone/components

import { useRef } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Logo } from '@plone/volto/components';
import { Container } from '@plone/components';
import { CookieConsentLink } from 'volto-cookie-consent-addon';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */

const messages = defineMessages({
  FCheading: {
    id: 'FCheading',
    defaultMessage: 'Contact us',
  },
  FCLink: {
    id: 'FCLink',
    defaultMessage: 'Contact info and team',
  },
  FCLinkherf: {
    id: 'FCLinkherf',
    defaultMessage: '/en/about-us/contact-info-and-team',
  },
  FAddress: {
    id: 'FAddress',
    defaultMessage: 'Address',
  },
  FAddressspan1: {
    id: 'FAddressspan1',
    defaultMessage: 'Building L',
  },

  FAddressspan2: {
    id: 'FAddressspan2',
    defaultMessage: 'Sports office L171',
  },
  FsocialmediaH: {
    id: 'FsocialmediaH',
    defaultMessage: 'Follow us',
  },
  FjoinusH: {
    id: 'FjoinusH',
    defaultMessage: 'How to join us',
  },
  Fjoinuss1: {
    id: 'Fjoinuss1',
    defaultMessage: 'Become a member',
  },
  Fjoinuss1herf: {
    id: 'Fjoinuss1herf',
    defaultMessage: '/en/join-us',
  },
  Fjoinuss2: {
    id: 'Fjoinuss2',
    defaultMessage: 'FAQ',
  },
  Fjoinuss2herf: {
    id: 'Fjoinuss2herf',
    defaultMessage: '/en/join-us/faq',
  },
  Ffeedback: {
    id: 'Ffeedback',
    defaultMessage: 'Feedback',
  },
  Ffeedbackss: {
    id: 'Ffeedbackss',
    defaultMessage: 'Feedback form',
  },
  FPrivasy: {
    id: 'FPrivasy',
    defaultMessage: 'Privacy policy ',
  },
  FPrivasyherf: {
    id: 'FPrivasyherf',
    defaultMessage:
      'https://www.jyu.fi/en/data-privacy/events-staff-training-and-academic-sports-sport-courses-at-the-university-privacy-notice',
  },

  FAccessibility: {
    id: 'FAccessibility',
    defaultMessage: 'Accessibility',
  },
  FAccessibilityherf: {
    id: 'FAccessibilityherf',
    defaultMessage: 'en/acessability',
  },
  FCookie: {
    id: 'FCookie',
    defaultMessage: 'Cookie settings',
  },
});

const Footer = ({ intl }) => {
  const consentRef = useRef();
  return (
    <>
      <footer id="footer">
        <Container layout className="footer">
          <div className="logo">
            <Logo />
          </div>
          <div className="footer-section">
            <div className="footer-block">
              <h2>{intl.formatMessage(messages.FCheading)}</h2>
              <a href="mailto:umove@jyu.fi">umove@jyu.fi</a>
              <a href={intl.formatMessage(messages.FCLinkherf)}>
                {intl.formatMessage(messages.FCLink)}
              </a>
            </div>
            <div className="footer-block">
              <h2>{intl.formatMessage(messages.FAddress)}</h2>
              <span>
                Keskussairaalantie 4,{' '}
                {intl.formatMessage(messages.FAddressspan1)}
              </span>
              <span>{intl.formatMessage(messages.FAddressspan2)}</span>
              <span>40600 Jyväskylä</span>
            </div>
          </div>

          <div className="footer-section">
            <div className="footer-block">
              <h2>{intl.formatMessage(messages.FsocialmediaH)}</h2>
              <a href="https://www.instagram.com/umovejkl/">Instagram</a>
              <a href="https://www.facebook.com/umovejkl/?locale=fi_FI">
                Facebook
              </a>
              <a href="https://www.youtube.com/@umovejyvaskylankorkeakoulu130">
                Youtube
              </a>
            </div>
            <div className="footer-block">
              <h2>{intl.formatMessage(messages.FjoinusH)} </h2>
              <a href={intl.formatMessage(messages.Fjoinuss1herf)}>
                {intl.formatMessage(messages.Fjoinuss1)}
              </a>
              <a href={intl.formatMessage(messages.Fjoinuss2herf)}>
                {intl.formatMessage(messages.Fjoinuss2)}
              </a>
            </div>
          </div>

          <div className="footer-section">
            <div className="footer-block">
              <h2>{intl.formatMessage(messages.Ffeedback)}</h2>
              <span>
                <a href="https://link.webropolsurveys.com/S/2E7AF6D832AFB86A">
                  {intl.formatMessage(messages.Ffeedbackss)}
                </a>
              </span>
            </div>
            <div className="footer-block lower-part">
              <a href={intl.formatMessage(messages.FPrivasyherf)}>
                {intl.formatMessage(messages.FPrivasy)}
              </a>
              <a href={intl.formatMessage(messages.FAccessibilityherf)}>
                {' '}
                {intl.formatMessage(messages.FAccessibility)}
              </a>
              <CookieConsentLink
                className="cookie-consent-link"
                consentRef={consentRef}
                readMoreUrl={
                  'https://www.jyu.fi/fi/tietosuoja/tapahtumat-henkilostokoulutukset-ja-korkeakoululiikunnan-kurssit-tietosuojailmoitus'
                }
              />
            </div>
          </div>
        </Container>
      </footer>
      <div ref={consentRef} />
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);

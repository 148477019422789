import { defineMessages } from 'react-intl';
import { addStyling } from '@plone/volto/helpers/Extensions/withBlockSchemaEnhancer';
import config from '@plone/volto/registry';

const messages = defineMessages({
  Target: {
    id: 'Target',
    defaultMessage: 'Target',
  },
  imageOverride: {
    id: 'Image override',
    defaultMessage: 'Image override',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  usocial: {
    id: 'Usocial',
    defaultMessage: 'USocial',
  },
  align: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  no_preview_image: {
    id: 'no_preview_image',
    defaultMessage: 'No preview image',
  },
  descriptionColor: {
    id: 'descriptionColor',
    defaultMessage: 'Description color',
  },
});

export const TeaserSchema = ({ intl }) => {
  const schema = {
    title: intl.formatMessage(messages.usocial),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['href', 'href2', 'href3', 'title'],
      },
    ],

    properties: {
      href: {
        title: 'Instagram',
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: [
          'Title',
          'Description',
          'hasPreviewImage',
          'image_field',
          'image_scales',
          '@type',
        ],
        allowExternals: true,
      },
      href2: {
        title: 'Facebook',
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: [
          'Title',
          'Description',
          'hasPreviewImage',
          'image_field',
          'image_scales',
          '@type',
        ],
        allowExternals: true,
      },
      href3: {
        title: 'Youtube',
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: [
          'Title',
          'Description',
          'hasPreviewImage',
          'image_field',
          'image_scales',
          '@type',
        ],
        allowExternals: true,
      },
      title: {
        title: intl.formatMessage(messages.title),
      },
      preview_image: {
        title: intl.formatMessage(messages.imageOverride),
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
        selectedItemAttrs: ['image_field', 'image_scales'],
      },
      openLinkInNewTab: {
        title: intl.formatMessage(messages.openLinkInNewTab),
        type: 'boolean',
      },
      noPreviewImage: {
        title: intl.formatMessage(messages.no_preview_image),
        type: 'boolean',
      },
      descriptionColor: {
        id: 'descriptionColor',
        defaultMessage: 'Description color',
      },
    },
    required: [],
  };

  addStyling({ schema, intl });

  schema.properties.styles.schema.properties.align = {
    widget: 'align',
    title: intl.formatMessage(messages.align),
    actions: ['left', 'right', 'center'],
    default: 'left',
  };

  const descriptionColors =
    config.blocks?.blocksConfig.highlight.descriptionColors;

  schema.properties.styles.schema.fieldsets[0].fields = ['align'];

  schema.properties.styles.schema.fieldsets[0].fields = ['descriptionColor'];

  schema.properties.styles.schema.properties.descriptionColor = {
    title: intl.formatMessage(messages.descriptionColor),
    widget: 'color_picker',
    colors: descriptionColors,
    default: 'highlight-custom-color-1',
  };

  return schema;
};

export const gridTeaserDisableStylingSchema = ({ schema, formData, intl }) => {
  schema.fieldsets = schema.fieldsets.filter((item) => item.id !== 'styling');
  return schema;
};

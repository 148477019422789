import { defineMessages } from 'react-intl';
import { insertInArray } from '@plone/volto/helpers/Utils/Utils';
import { addStyling } from '@plone/volto/helpers/Extensions/withBlockSchemaEnhancer';
import config from '@plone/volto/registry';

const messages = defineMessages({
  Description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  Title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  ButtonText: {
    id: 'ButtonText',
    defaultMessage: 'Button Text',
  },
  descriptionColor: {
    id: 'descriptionColor',
    defaultMessage: 'Description color',
  },
  ToggleAppearance: {
    id: 'Toggle appearance',
    defaultMessage: 'Toggle appearance',
  },
});

export const imageBlockSchemaEnhancer = ({ formData, schema, intl }) => {
  if (formData.url) {
    schema.fieldsets[0].fields = insertInArray(
      schema.fieldsets[0].fields,
      'description',
      1,
    );
    schema.fieldsets[0].fields = insertInArray(
      schema.fieldsets[0].fields,
      'title',
      1,
    );

    // Insert the new button text field after title
    schema.fieldsets[0].fields = insertInArray(
      schema.fieldsets[0].fields,
      'buttonText', // The new field key
      2, // Adjust index as needed based on where you want it to appear
    );

    // Insert the new button text field after title
    schema.fieldsets[0].fields = insertInArray(
      schema.fieldsets[0].fields,
      'toggleappearance', // The new field key
      3, // Adjust index as needed based on where you want it to appear
    );

    schema.properties.description = {
      title: intl.formatMessage(messages.Description),
      widget: 'textarea',
    };
    schema.properties.title = {
      title: intl.formatMessage(messages.Title),
    };
    // Adding the new button text property
    schema.properties.buttonText = {
      title: intl.formatMessage(messages.ButtonText),
      type: 'string', // Assuming it's a simple text field
      description: 'Text that will appear on the button', // Optional description
    };
    // Add the boolean field
    schema.properties.toggleappearance = {
      title: intl.formatMessage(messages.ToggleAppearance),
      type: 'boolean',
      description: 'Toggle appearance of the block',
    };
  }
  schema.properties.align.default = 'center';
  schema.properties.align.actions = ['left', 'right', 'center', 'wide', 'full'];
  schema.properties.size.default = 'l';
  schema.properties.size.disabled =
    formData.align === 'full' ||
    formData.align === 'wide' ||
    formData.align === 'center';

  addStyling({ schema, intl });

  const descriptionColors =
    config.blocks?.blocksConfig.highlight.descriptionColors;

  schema.properties.styles.schema.fieldsets[0].fields = ['descriptionColor'];

  schema.properties.styles.schema.properties.descriptionColor = {
    title: intl.formatMessage(messages.descriptionColor),
    widget: 'color_picker',
    colors: descriptionColors,
    default: 'highlight-custom-color-1',
  };

  return schema;
};

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  headline: {
    id: 'Headline',
    defaultMessage: 'Headline',
  },
  grid: {
    id: 'Grid',
    defaultMessage: 'Grid',
  },
  widengrid: {
    id: 'Widengrid',
    defaultMessage: 'Widen grid',
  },
  mobileslider: {
    id: 'Mobile slider',
    defaultMessage: 'Mobile slider',
  },
  gridmobileslider: {
    id: 'Gridmobileslider',
    defaultMessage: 'Grid mobile slider',
  },
});

export const GridBlockSchema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.grid),
    block: 'grid',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['headline', 'widengrid', 'mobileslider', 'gridmobileslider'],
      },
    ],

    properties: {
      headline: {
        title: intl.formatMessage(messages.headline),
      },

      widengrid: {
        title: intl.formatMessage(messages.widengrid),
        type: 'boolean',
      },
      mobileslider: {
        title: intl.formatMessage(messages.mobileslider),
        type: 'boolean',
      },
      gridmobileslider: {
        title: intl.formatMessage(messages.gridmobileslider),
        type: 'boolean',
      },
    },
    required: [],
  };
};

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Video: {
    id: 'Video',
    defaultMessage: 'UVideo',
  },
  VideoURL: {
    id: 'Video URL',
    defaultMessage: 'Video URL',
  },
  Preview_image: {
    id: 'Preview Image URL',
    defaultMessage: 'Preview Image URL',
  },
  Alignment: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  headline: {
    id: 'Headline',
    defaultMessage: 'Headline',
  },
  content: {
    id: 'Content',
    defaultMessage: 'Content',
  },
  buttontext: {
    id: 'Buttontext',
    defaultMessage: 'Buttontext',
  },
  Target: {
    id: 'Target',
    defaultMessage: 'Target',
  },
});

export const VideoBlockSchema = (props) => ({
  title: props.intl.formatMessage(messages.Video),
  block: 'Video',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [
        'url',
        'preview_image',
        'align',
        'headline',
        'content',
        'buttontext',
        'href',
      ],
    },
  ],

  properties: {
    href: {
      title: props.intl.formatMessage(messages.Target),
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: [
        'Title',
        'Description',
        'hasPreviewImage',
        'image_field',
        'image_scales',
        '@type',
      ],
      allowExternals: true,
    },
    url: {
      title: props.intl.formatMessage(messages.VideoURL),
      widget: 'url',
    },
    preview_image: {
      title: props.intl.formatMessage(messages.Preview_image),
      widget: 'url',
    },
    align: {
      title: props.intl.formatMessage(messages.Alignment),
      widget: 'align',
    },
    headline: {
      title: props.intl.formatMessage(messages.headline),
      widget: 'textarea',
      maxLength: 250,
    },
    content: {
      title: props.intl.formatMessage(messages.content),
      widget: 'textarea',
      maxLength: 250,
    },
    buttontext: {
      title: props.intl.formatMessage(messages.buttontext),
      widget: 'textarea',
      maxLength: 250,
    },
  },
  required: [],
});

export default VideoBlockSchema;

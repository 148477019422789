/**
 * View video block.
 * @module components/manage/Blocks/Video/View
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Body from '@plone/volto/components/manage/Blocks/Video/Body';
import { withBlockExtensions } from '@plone/volto/helpers';
import cx from 'classnames';
import { useIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  marketingNotAccepted: {
    id: 'marketingNotAccepted',
    defaultMessage:
      'To view this content, please accept the cookies for targeted content and social media embeds.',
  },
});

const View = (props) => {
  const { data, className } = props;
  const [marketingAccepted, setMarketingAccepted] = useState(false);

  useEffect(() => {
    const getCookieValue = (name) =>
      document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() ||
      '';
    const cookieContents = getCookieValue('cookie-consent');
    setMarketingAccepted(cookieContents.includes('marketing'));
  }, []);

  const href = data.href?.[0];
  const intl = useIntl();

  return (
    <div
      className={cx(
        'block video align uvideo',
        {
          center: !Boolean(data.align),
        },
        data.align,
        className,
      )}
    >
      <div className="information-container">
        {data.headline && <h2>{data.headline}</h2>}
        {data.content && <p>{data.content}</p>}
        {href && (
          <a href={href['@id']}>
            <button>{data.buttontext}</button>
          </a>
        )}
      </div>
      {marketingAccepted ? (
        <Body data={data} />
      ) : (
        <div>
          <p>{intl.formatMessage(messages.marketingNotAccepted)}</p>
        </div>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withBlockExtensions(View);

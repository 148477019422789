import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import cx from 'classnames';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
  facebook_link_aria_label: {
    id: 'facebook_link_aria_label',
    defaultMessage: 'Visit our Facebook page',
  },
  instagram_link_aria_label: {
    id: 'instagram_link_aria_label',
    defaultMessage: 'Visit our Instagram page',
  },
  youtube_link_aria_label: {
    id: 'youtube_link_aria_label',
    defaultMessage: 'Visit our YouTube page',
  },
});

const TeaserDefaultTemplate = (props) => {
  const { className, data, style } = props;
  const intl = useIntl();
  const href = data.href?.[0];

  const href2 = data.href2?.[0];
  const href3 = data.href3?.[0];

  return (
    <div className={cx('block usocial', className)} style={style}>
      <>
        <div className="usocial default">
          <div className="content">
            <div className="highlight">
              <span className="usocial-headline">{data?.title}</span>
            </div>
            <div className="socialmedia-icons">
              <a
                href={href?.['@id']}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={intl.formatMessage(
                  messages.instagram_link_aria_label,
                )}
              >
                <div className="icon instagram"> </div>
              </a>
              <a
                href={href2?.['@id']}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={intl.formatMessage(
                  messages.facebook_link_aria_label,
                )}
              >
                <div className="icon facebook"> </div>
              </a>

              <a
                href={href3?.['@id']}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={intl.formatMessage(
                  messages.youtube_link_aria_label,
                )}
              >
                <div className="icon youtube"> </div>
              </a>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

TeaserDefaultTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default TeaserDefaultTemplate;
